import { Fragment, useContext, useEffect, useState } from "react";
import log from "../misc/Logger";
import { Context } from "../App";
import { getActionSetConfirm, getAllContentEntities, icbControllerGenerel02, nodeCRUD, toDrupalDate } from "../misc/Functions";
import { CRUD, DataGridActions, DataGridUpdate, TypeContext } from "../misc/Types";
import CRUDList from "./CRUDList";
import { GridColDef } from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import { useTranslation } from "react-i18next";

interface PropsMyPlayers {
    // checkboxSelection?: boolean,
    /*
    Command button text for what to do with all selected rows. If specified then there will
    be a checkbox at the start of every line in grid
    */
    labelSaveSelection?: string,
    onClose?: (actOnSelected: boolean) => void, // call with true if dialog action is to be performed. Call with false if dialog action is cancelled
}

export default function MyPlayers(props: PropsMyPlayers) {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('MyPlayers');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const [rows, setRows] = useState<Array<any>>([])

    useEffect(() => {
        setRows(
            state.allPlayers.map((item) => ({
                id: item.id,
                field_player_first_name: item.attributes.field_player_first_name,
                field_player_last_name: item.attributes.field_player_last_name,
                field_player_date_of_birth: new Date(item.attributes.field_player_date_of_birth),
            })))
    }, [state.allPlayers])

    const columns: GridColDef<(typeof rows)[number]>[] = [
        { field: 'id', headerName: 'ID', width: 10, },
        { field: 'field_player_first_name', headerName: t('MyPlayers04'), flex: 1, editable: true, },
        { field: 'field_player_last_name', headerName: t('MyPlayers05'), flex: 1, editable: true, },
        { field: 'field_player_date_of_birth', headerName: t('MyPlayers06'), flex: 1, editable: true, type: 'date' },
    ]

    function processRowUpdate() {
        return (dataGridUpdate: DataGridUpdate) =>
            new Promise((resolve, reject) => {
                if (dataGridUpdate.delete) {
                    // delete
                    const playerID = dataGridUpdate.id?.toString()
                    // delete player from teams, practice attendance lists and players. Do this as back end job!
                    icbControllerGenerel02(state, {
                        opr: "delete_player",
                        playerID: playerID
                    })
                        .then((resp) => {
                            if (resp.ok) {
                                resolve(dataGridUpdate.rows)
                                // rereadUser(state, dispatch, state.user.data.id || '')
                                getAllContentEntities(state, dispatch, 'node--player')
                                getAllContentEntities(state, dispatch, 'node--practice')                            
                                getAllContentEntities(state, dispatch, 'node--team')                            
                            } else {
                                reject(new Error(resp.error))
                            }
                        })
                } else if (dataGridUpdate.newRow) {
                    // create or update
                    // check both first and last name specified
                    if (!dataGridUpdate.newRow.field_player_first_name || !dataGridUpdate.newRow.field_player_last_name || !dataGridUpdate.newRow.field_player_date_of_birth) {
                        reject(new Error(t('MyPlayers07')))
                        return
                    }
                    // check we don't have player of that first name / last name already
                    if (dataGridUpdate.rows.filter(x => x.field_player_first_name === dataGridUpdate.newRow!.field_player_first_name && x.field_player_last_name === dataGridUpdate.newRow!.field_player_last_name).length > 1) {
                        reject(new Error(t('MyPlayers08')))
                        return
                    }
                    const create = dataGridUpdate.newRow.isNew //dataGridUpdate.rows.find(x => x.id === dataGridUpdate.newRow!.id)
                    const playerCreate = {
                        type: 'node--player',
                        attributes: {
                            // Player title is first name last name and date of birth
                            title: `${dataGridUpdate.newRow!.field_player_first_name} ${dataGridUpdate.newRow!.field_player_last_name} ${dataGridUpdate.newRow!.field_player_date_of_birth.toISOString().substring(0, 10)} `,
                            field_player_first_name: dataGridUpdate.newRow!.field_player_first_name,
                            field_player_last_name: dataGridUpdate.newRow!.field_player_last_name,
                            field_player_date_of_birth: toDrupalDate(dataGridUpdate.newRow!.field_player_date_of_birth.toISOString()),
                        }
                    }
                    const playerUpdate = { ...playerCreate, id: dataGridUpdate.newRow!.id }
                    nodeCRUD(state, dispatch, create ? CRUD.Create : CRUD.Update, create ? playerCreate : playerUpdate)
                        .then((resp) => {
                            // not an error if we get json structure
                            if (resp.data) {
                                resolve(dataGridUpdate.rows)
                            } else {
                                log.error(resp);
                                dispatch(getActionSetConfirm(resp));
                            }
                        })
                } else {
                    resolve(dataGridUpdate.rows)
                }
            })
    }

    return (
        <Fragment>
            <CRUDList
                rows={rows}
                columns={columns}
                actions={[DataGridActions.save, DataGridActions.cancel, DataGridActions.delete, DataGridActions.edit]}
                processRowUpdate={processRowUpdate}
                processRowAdd={() => {
                    // create new row here as we need a non default type, ie. "date"
                    const id = randomId();
                    // const res = [...rows, { id: id, field_player_first_name: '', field_player_date_of_birth: new Date(), isNew: true }]
                    const row = { id: id, field_player_first_name: '', field_player_date_of_birth: new Date(), isNew: true }
                    return [id, row]
                }}
                labelAddRow={t('MyPlayers02')}
                labelSaveSelection={t('MyPlayers03')}
                gridColumnVisibilityModel={{ id: false }}
                columnFocusOnNew="field_player_first_name"
                // checkboxSelection={props.checkboxSelection !== undefined && props.checkboxSelection}
                onClose={(actOnSelected: boolean) => props.onClose && props.onClose(actOnSelected)}
                deleteWarning={t('MyPlayers01')}
            />
        </Fragment>
    )
}