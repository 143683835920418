import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SportsBasketballRoundedIcon from '@mui/icons-material/SportsBasketballRounded';
import { Typography, useTheme } from '@mui/material';
import { ActionSetSelectedExercisePool, ActionSetSelectedGroup, TypeContext } from '../misc/Types';
import { Context } from '../App';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import { goHome, navigateExerciseCreate } from '../misc/Functions';
import log from "../misc/Logger";
import { useTranslation } from 'react-i18next';
import FolderIcon from '@mui/icons-material/Folder';
import { AddCircle } from '@mui/icons-material';
import { DEFAULT_GROUP } from '../misc/Constants';
import { Fragment, useContext } from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SportsBasketballOutlinedIcon from '@mui/icons-material/SportsBasketballOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

export default function BottomAppBar() {
  const { state, dispatch } = useContext(Context) as TypeContext;
  const navigate = useNavigate();
  const { t } = useTranslation();
  log.debug('BottomAppBar');
  const location = useLocation(); // Use the useLocation hook
  const theme = useTheme();
  // Function to determine if a tab is selected based on the route
  const isSelected = (path: string) => {
    return location.pathname === path;
  };

  return (
    <Fragment >
      <CssBaseline />
      <AppBar position="fixed" sx={{
        top: 'auto', bottom: 0, paddingBottom: state.nativeApp ? 3 : 0, backgroundColor: theme.palette.action.disabled,
        backdropFilter: 'blur(4px)',
      }}>
        <Toolbar style={{ justifyContent: 'space-between', marginLeft: 8, marginRight: 8 }}>

          {/* Home Tab */}
          <div style={{ textAlign: 'center', color: isSelected('/home') ? '#DD6F20' : theme.palette.text.primary }} // Conditional styling
            onClick={() => { goHome(dispatch, navigate) }}
          >
            {isSelected('/home') ? <HomeRoundedIcon /> : <HomeOutlinedIcon />}
            <Typography
              variant="body2"
              style={{
                fontSize: '12px',
                marginTop: -6,
                padding: '0',
              }}>
              {t('SmallScreenICBAppBar00')}
            </Typography>
          </div>

          {/* Drills Tab */}
          <div style={{ textAlign: 'center', color: ['/', '/icb', '/club', '/mine'].includes(location.pathname) ? '#DD6F20' : theme.palette.text.primary }} // Conditional styling
            onClick={() => {
              let actionSetSelectedExercisePool: ActionSetSelectedExercisePool = { type: 'setSelectedExercisePool', pool: 'icb' }
              dispatch(actionSetSelectedExercisePool);
              let actionSetSelectedGroup: ActionSetSelectedGroup = { type: 'setSelectedGroup', group: state.allGroups.find(x => x.id === DEFAULT_GROUP)! }
              dispatch(actionSetSelectedGroup);
              navigate('/icb');
              if (state.showPracticeProgram) {
                let action = { type: 'showPracticeProgram', show: !state.showPracticeProgram };
                dispatch(action);
              }

              //we use this to scroll so the search is hided behind the appbar
              const scrollToTopId = state.portrait ? 'initialViewExercisesPortrait' : 'initialViewExercisesLandscape';
              setTimeout(() => {
                const scrollToTop = document.getElementById(scrollToTopId);
                if (scrollToTop) {
                  scrollToTop.scrollIntoView({ behavior: 'smooth' });
                }
              }, 0);

            }}
          >
            {['/', '/icb', '/club', '/mine'].includes(location.pathname) ? <SportsBasketballRoundedIcon /> : <SportsBasketballOutlinedIcon />}
            <Typography variant="body2" style={{ fontSize: '12px', marginTop: -6, padding: '0' }}>
              {t('SmallScreenICBAppBar01')}
            </Typography>
          </div>

          {/* Practices */}
          <div
            style={{ textAlign: 'center', color: isSelected('/practices') ? '#DD6F20' : theme.palette.text.primary }}
            onClick={() => { navigate('/practices'); }}
          >
            {isSelected('/practices') ? <FolderIcon /> : <FolderOutlinedIcon />}
            <Typography variant="body2" style={{ fontSize: '12px', marginTop: -6, padding: '0' }}>
              {t('SmallScreenICBAppBar02')}
            </Typography>
          </div>

          {/* Create Exercise Tab */}
          <div style={{ textAlign: 'center', color: isSelected('/exercisecreate') ? '#DD6F20' : theme.palette.text.primary }} // Conditional styling
            onClick={() => navigateExerciseCreate(state, dispatch, navigate)}>
            {isSelected('/exercisecreate') ? <AddCircle /> : <AddCircleOutlineRoundedIcon />}
            <Typography variant="body2" style={{ fontSize: '12px', marginTop: -6, padding: '0' }}>
              {t('SmallScreenICBAppBar03')}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
    </Fragment >
  )
}
