import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Context } from "../App";
import { DataGridActions, DataGridUpdate, TypeContext } from "../misc/Types";
import { getActionSetConfirm } from "../misc/Functions";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import { Box, Button, FormControl, FormHelperText, IconButton, InputLabel, Menu, MenuItem, Select, useTheme, } from "@mui/material";
import {
    DataGrid, GridRowsProp, GridRowId, GridRowModesModel, GridRowModes, GridActionsCellItem, GridEventListener, GridRowEditStopReasons,
    GridRowModel, GridColDef, useGridApiRef, GridColumnVisibilityModel,
    GridRowSelectionModel,
} from '@mui/x-data-grid';
import { randomId } from "@mui/x-data-grid-generator";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Close';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import { Tooltip } from "@mui/material";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// See https://mui.com/x/react-data-grid/editing/

interface PropsCRUDList {
    rows: GridRowsProp,
    columns: GridColDef[],
    actions: DataGridActions[],
    labelAddRow?: string,
    labelSaveSelection?: string,
    processRowPrint?: (id: GridRowId) => any,
    processRowSelect?: (id: GridRowId) => any,
    processRowViewClubDocument?: (id: GridRowId) => any,
    processRowUpdate?: (dataGridUpdate: DataGridUpdate) => any,
    processRowAdd?: () => any,
    processRowDownload?: (id: GridRowId) => any,
    processRowMailTo?: (id: GridRowId) => any,
    dropDownFilterList?: string[],
    dropDownFilterLabel?: string,
    dropDownFilterColumn?: string,
    dropDownFilterDefaultValue?: string,
    gridColumnVisibilityModel: GridColumnVisibilityModel,
    columnFocusOnNew: string,
    onClose?: (ok: boolean) => void, // used if this component is shown in a dailog. Call with true if dialog action is to be performed. Call with false if dialog action is cancelled
    deleteWarning?: string,
    onClickAddRow?: (event: any) => any,
}

// Description of an aciton in a row 
interface TypeRowAction {
    tooltip: string,
    icon: React.ReactElement, //OverridableComponent<SvgIconTypeMap>
    onClick: (id: GridRowId) => any,
}

export default function CRUDList(props: PropsCRUDList) {
    // Get state and dispatcher
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const theme = useTheme();
    log.debug('CRUDList');

    const [disabled, setDisabled] = useState(false)
    const [rows, setRows] = useState<GridRowsProp>(props.rows || [])
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const apiRef = useGridApiRef();
    // const [rerenderKey, setRerenderKey] = useState<number>(0);

    // Default checkbox selection
    useEffect(() => {
        log.debug('CRUDList, useEffect, setRows');
        setRows(props.rows)
        if (props.rows.length > 0) {
            setRowSelectionModel(state.CRUDListSelectedValues || [])
        }
    }, [props.rows])

    // // Needed when refresh/F5
    // useEffect(() => {
    //     log.debug('CRUDList, useEffect, setFilterModel');
    //     if (props.dropDownFilterList) {
    //         apiRef.current.setFilterModel({
    //             items: [{ field: props.dropDownFilterColumn || '', operator: 'equals', value: props.dropDownFilterDefaultValue }],
    //         })
    //     }
    // }, [props.dropDownFilterDefaultValue])

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true
    }

    const handleEditClick = (id: GridRowId) => () => {
        // delete rows not saved because we only want to edit one row at a time
        setRows(rows.filter(x => !x.isNew))
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
    }

    const handleSaveClick = (id: GridRowId) => () => setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const handleDoneClick = (id: GridRowId) => () => props.processRowSelect && props.processRowSelect(id)
    const handlePrintClick = (id: GridRowId) => () => props.processRowPrint && props.processRowPrint(id)
    const handleViewClubDocumentClick = (id: GridRowId) => () => props.processRowViewClubDocument && props.processRowViewClubDocument(id)
    const handleDownloadClick = (id: GridRowId) => () => props.processRowDownload && props.processRowDownload(id)
    const handleRowMailToMe = (id: GridRowId) => () => props.processRowMailTo && props.processRowMailTo(id)
    const handleArrowDownClick = (id: GridRowId) => () => handleClickMove(id, false)
    const handleArrowUpClick = (id: GridRowId) => () => handleClickMove(id, true)
    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => setRowModesModel(newRowModesModel)

    // Ref to callback/promise in the calling environment
    const update = props && props.processRowUpdate ? props.processRowUpdate({ rows: [] }) : null

    // Add new row default is to open a new row for user input. If a different behavior is required then supply property processRowAdd
    const handleClickAddRow = () => {
        log.debug('CRUDList, handleClickAddRow');
        // Don't allow edit of more than one row at a time
        if (
            // we already have a new row
            rows.find(x => x.isNew)
            // we are already editing a row - it seems we have to check that the row in rowModesModel is also in rows!
            || (Object.keys(rowModesModel).length > 0 && rows.find(x => Object.keys(rowModesModel).includes(x.id)))
        ) {
            dispatch(getActionSetConfirm(t('CRUDList14')))
            setTimeout(() => {
                log.debug('CRUDList, in setTimeout, setDisabled(false)');
                setDisabled(false)
            }, state.nativeApp ? 250 : 0)
            return;
        }

        var id = randomId();
        var rowsLocal;
        if (props.processRowAdd) {
            // create new row in calling environment
            let row
            [id, row] = props.processRowAdd()
            rowsLocal = [...rows, row]
        } else {
            // create new row here
            rowsLocal = [...rows, { id: id, [props.columnFocusOnNew]: '', isNew: true }]
        }
        setRows(rowsLocal);
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: props.columnFocusOnNew }
        })
        // window.scrollTo(0, 1000); // scroll down so focused edit field is visible

        setTimeout(() => {
            log.debug('CRUDList, in setTimeout, setDisabled(false)');
            setDisabled(false)
        }, state.nativeApp ? 250 : 0)
    };

    // Move row up or down
    function handleClickMove(id: GridRowId, up: boolean) {
        let row = rows.find(x => x.id === id)
        let idxA = rows.indexOf(row!)
        if ((up && idxA === 0) || (!up && idxA === rows.length - 1))
            return;
        let idxB = up ? idxA - 1 : idxA + 1
        let rowsLocal = [...rows]
        const rowA = rowsLocal[idxA]
        rowsLocal[idxA] = rowsLocal[idxB]
        rowsLocal[idxB] = rowA
        setRows(rowsLocal);
        let dataGridUpdate: DataGridUpdate = {
            rows: rowsLocal,
        }
        update(dataGridUpdate)
    }

    const processRowUpdate = async (newRow: GridRowModel) => { //, oldRow: GridRowModel) => {
        // set new or updated row
        const updatedRow = { ...newRow } //, isNew: false };
        // set all rows after new or updated row 
        const rowsLocal = rows.map((row) => (row.id === newRow.id ? updatedRow : row));
        // set update structure we can pass to parent object
        let dataGridUpdate: DataGridUpdate = {
            rows: rowsLocal,
            newRow: updatedRow,
        }
        // call parent object with update structure
        await update(dataGridUpdate)
        // update datagrid now that parent has handled update structure
        // If promise in line above is reject next two lines are not executed!
        setRows(rowsLocal);
        return updatedRow
    }

    const handleDeleteClick = (id: GridRowId) => async () => {
        dispatch(getActionSetConfirm(props.deleteWarning || t('CRUDList13'), 'ok', async () => {
            try {
                let dataGridUpdate: DataGridUpdate = {
                    rows: rows.filter(x => x.id !== id),
                    id: id,
                    delete: true,
                }
                const response = await update(dataGridUpdate)
                setRows(response);
            } catch (error: any) {
                dispatch(getActionSetConfirm(error.message));
            }
        }));
    };

    function getRowActions(gridRowMode: GridRowModes): Array<TypeRowAction> {
        let rowAction: Array<TypeRowAction> = []
        if (gridRowMode === GridRowModes.Edit) {
            if (props.actions.includes(DataGridActions.save))
                rowAction.push({ tooltip: 'Save', icon: <SaveIcon style={{ color: theme.palette.primary.main }} />, onClick: handleSaveClick })
            if (props.actions.includes(DataGridActions.cancel))
                rowAction.push({ tooltip: 'Cancel', icon: <CancelIcon />, onClick: handleCancelClick })
        } else {
            if (props.actions.includes(DataGridActions.edit))
                rowAction.push({ tooltip: t('CRUDList00'), icon: <EditIcon style={{ color: theme.palette.primary.main }} />, onClick: handleEditClick })
            if (props.actions.includes(DataGridActions.up))
                rowAction.push({ tooltip: t('CRUDList01'), icon: <ArrowUpward />, onClick: handleArrowUpClick })
            if (props.actions.includes(DataGridActions.down))
                rowAction.push({ tooltip: t('CRUDList02'), icon: <ArrowDownward />, onClick: handleArrowDownClick })
            if (props.actions.includes(DataGridActions.print))
                rowAction.push({ tooltip: t('CRUDList03'), icon: <VisibilityIcon style={{ color: theme.palette.primary.main }} />, onClick: handlePrintClick });
            if (props.actions.includes(DataGridActions.viewClubDocument))
                rowAction.push({ tooltip: t('CRUDList07'), icon: <VisibilityIcon style={{ color: theme.palette.primary.main }} />, onClick: handleViewClubDocumentClick })
            if (props.actions.includes(DataGridActions.download))
                rowAction.push({ tooltip: t('CRUDList12'), icon: <DownloadRoundedIcon style={{ color: theme.palette.primary.main }} />, onClick: handleDownloadClick })
            if (props.actions.includes(DataGridActions.mailTo))
                rowAction.push({ tooltip: t('CRUDList10'), icon: <SendRoundedIcon style={{ color: theme.palette.secondary.main }} />, onClick: handleRowMailToMe })
            if (props.actions.includes(DataGridActions.select))
                rowAction.push({ tooltip: t('CRUDList04'), icon: <PendingActionsIcon />, onClick: handleDoneClick })
            if (props.actions.includes(DataGridActions.delete))
                rowAction.push({ tooltip: t('CRUDList05'), icon: <DeleteIcon style={{ color: 'lightgray' }} />, onClick: handleDeleteClick })
        }
        return rowAction
    }

    // Actions in portrait mode. If row is in edit mode then show save and cancel options. Otherwise, show popup menu with a number of options
    const ActionMenu = (row: any) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event: any) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const rowAction = getRowActions(rowModesModel[row.row.id]?.mode)
        if (rowModesModel[row.row.id]?.mode === GridRowModes.Edit) {
            // Show actions inline on row. On iPhone you should see the save button. You can not rely on 'Enter' acting as save
            return rowAction.map((item, index) => {
                return (
                    <Tooltip
                        key={index}
                        title={item.tooltip}
                    >
                        <GridActionsCellItem
                            icon={item.icon}
                            onClick={item.onClick(row.row.id)}
                            label=''
                        />
                    </Tooltip>
                )
            })
        } else {
            // Show all actions under a popup menu
            return (
                <Fragment>
                    <IconButton onClick={handleClick}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {
                            rowAction.map((item, index) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        onClick={item.onClick(row.row.id)}
                                    >
                                        {/* {item.icon} */}
                                        {item.tooltip}
                                    </MenuItem>
                                )
                            })
                        }
                    </Menu>
                </Fragment>
            );
        }
    };

    // Data columns plus action columns. Portrait mode
    const columnsPortrait: GridColDef[] = (props.columns || []).concat(
        [
            {
                field: 'actions',
                type: 'actions',
                width: 45,
                renderCell: (params) => <ActionMenu row={params.row} />,
            }
        ]
    )

    // Data columns plus action columns. Landscape mode.
    // const columnsLandscape: GridColDef[] = (props.columns || []).concat(
    //     [
    //         {
    //             field: 'actions',
    //             type: 'actions',
    //             // headerName: '',
    //             width: getActionColumnWidth() * 45,
    //             // cellClassName: 'actions',
    //             getActions: ({ id }) => {
    //                 // console.log('getActions. id:', id)

    //                 const rowAction = getRowActions(rowModesModel[id]?.mode)

    //                 // Show action inline on row
    //                 return rowAction.map((item, index) => {
    //                     return (
    //                         <Tooltip title={item.tooltip}>
    //                             <GridActionsCellItem
    //                                 key={index}
    //                                 icon={item.icon}
    //                                 onClick={item.onClick(id)}
    //                                 label=''
    //                             />
    //                         </Tooltip>
    //                     )
    //                 })
    //             },
    //         }
    //     ]
    // )

    const handleProcessRowUpdateError = useCallback((error: Error) => {
        dispatch(getActionSetConfirm(error.message));
    }, []);

    // Only show dropdown if we have values for dropdown
    function QuickSearchToolbar() {
        const [selectedValue, setSelectedValue] = useState(props.dropDownFilterDefaultValue || '');
        const handleChange = (event: { target: { value: any; }; }) => {
            const value = event.target.value;
            setSelectedValue(value);

            apiRef.current.setFilterModel({
                items: [{ field: props.dropDownFilterColumn || '', operator: 'equals', value: value }],
            });
        };

        if (props.dropDownFilterList) {
            return (
                <Box width={'100%'}>
                    <FormControl fullWidth>
                        <InputLabel id="quick-search-select-label">{props.dropDownFilterLabel}</InputLabel>
                        <Select
                            variant="outlined"
                            labelId="quick-search-select-label"
                            id="quick-search-select"
                            value={selectedValue}
                            label={props.dropDownFilterLabel}
                            onChange={handleChange}
                            sx={{ height: '50px' }} // Set the height here
                        >
                            <MenuItem value="">
                                <em>{t('CRUDList06')}</em>
                            </MenuItem>
                            {props.dropDownFilterList?.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{t('CRUDList11')}</FormHelperText>
                    </FormControl>
                </Box>
            );
        } else
            return (<></>)
    }

    // Add new row button
    function addNewRowButton() {
        // Do nothing if we have no text for add new row button
        if (!props.labelAddRow)
            return (<Fragment></Fragment>)

        // Calling environment handles all concerning click on 'add new row button' - ie upload clubdocument
        if (props.onClickAddRow)
            return (
                <Button
                    disabled={disabled}
                    variant="contained"
                    component="label"
                >
                    {props.labelAddRow}
                    <input
                        type="file"
                        hidden
                        onChange={(event) => {
                            log.debug('CRUDList, addNewRowButton(), input type file, setDisabled(true)');
                            setDisabled(true)
                            props.onClickAddRow && props.onClickAddRow(event)
                        }}
                    />
                </Button>
            )

        // This component handles click on 'add new row button' which might include call back to calling environment
        return (
            <Button
                disabled={disabled}
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => {
                    log.debug('CRUDList, addNewRowButton(), button, setDisabled(true)');
                    setDisabled(true)
                    handleClickAddRow()
                }}
                sx={{ textTransform: 'none' }}
            >
                {props.labelAddRow}
            </Button>
        )
    }

    // delete row in edit mode when new row is clicked. We only want one row in edit mode at a time
    const handleEventOnRowClick: GridEventListener<'rowClick'> = (
        // params, // GridRowParams
        // event, // MuiEvent<React.MouseEvent<HTMLElement>>
        // details, // GridCallbackDetails
    ) => {
        // alert(`Movie "${params.row.id}" clicked`);
        // delete rows not saved because we only want to edit one row at a time
        setRows(rows.filter(x => !x.isNew))
        // provoke error:
        // const errorTest = state.allUsers[10].type
        // console.log(errorTest)
    };

    return (
        <Fragment>
            {/* <div>
                CRUDList.tsx: {JSON.stringify(rows)}
            </div> */}
            <Box sx={{
                // maxWidth: '700px',
                margin: 'auto',
                width: '95%',
                maxWidth: '700px',
                paddingBottom: 45, // allow for space below list so iPhone keyboard pop up does not cover list
                paddingTop: 2,
                bgcolor: 'background.paper', // Fondo claro para el contenedor
                borderRadius: '8px', // Bordes redondeados

            }}>

                {/* This keeps the 2 buttons aligned to the extrems, right and left. */}
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between', // Espacia los elementos a los extremos
                }}>

                    {
                        props.labelSaveSelection &&
                        <Button
                            variant="contained"
                            startIcon={<SendRoundedIcon />}
                            onClick={() => props.onClose && props.onClose(true)}
                            sx={{ textTransform: 'none' }}
                        >
                            {props.labelSaveSelection}
                        </Button>
                    }

                    {
                        /* Perform action on all selected rows.
                        Action is performed higher up the compoent hierarchy.
                        For now, this only takes place when the list is called in a dialog context                
                        */
                    }

                    {/* Add new row button */}
                    {addNewRowButton()}
                </Box>

                <Box sx={{
                    display: 'flex',
                }}>
                    <QuickSearchToolbar />
                </Box>

                <DataGrid
                    apiRef={apiRef}

                    checkboxSelection={props.labelSaveSelection !== undefined}
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                        dispatch({ type: 'setCRUDListSelectedValues', selectedValues: newRowSelectionModel })
                    }}

                    rows={rows}
                    rowHeight={35} // Altura aumentada para las filas
                    columns={columnsPortrait}

                    editMode="row"

                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector

                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    columnVisibilityModel={props.gridColumnVisibilityModel}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}

                    autoHeight
                    // autoPageSize
                    // pageSizeOptions={[25, 50, 100]}
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-columnHeaders': {
                            fontFamily: 'PT Sans, sans-serif',
                            color: theme.palette.primary.main,
                            fontWeight: 'bold', // Establece el peso de la fuente en negrita
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold' // Asegura que el título de cada columna esté en negrita
                        },
                        // '& .MuiDataGrid-cell': {
                        //     fontSize: '16px' // set font size so iPhone does not scale when user edits cell content
                        // },
                        '& .MuiInputBase-input': {
                            fontSize: '16px', // Establece el tamaño del texto en los campos de entrada de edición a 16px
                        }
                    }}

                    // Test listening to event - ie dont allow edit if edit is open on another row
                    onRowClick={handleEventOnRowClick}

                />
            </Box>
        </Fragment>
    );
}
