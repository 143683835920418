import React, { useState, useEffect } from 'react';
import { Box, Divider, Drawer, Fab, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, styled, useTheme } from '@mui/material';
import SortByAlphaRoundedIcon from '@mui/icons-material/SortByAlphaRounded';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { ActionSetSelectedExercisePool, ActionSetSelectedGroup, TypeContext } from '../misc/Types';
import { Context } from '../App';
import { DEFAULT_GROUP } from '../misc/Constants';
import SportsBasketballOutlinedIcon from '@mui/icons-material/SportsBasketballOutlined';
import SportsBasketballRoundedIcon from '@mui/icons-material/SportsBasketballRounded';
import { getImageURL, logout, navigateExerciseCreate } from '../misc/Functions';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { Groups2 } from '@mui/icons-material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArticleIcon from '@mui/icons-material/Article';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import log from "../misc/Logger";

const drawerWidth = 225;
const collapsedDrawerWidth = 57;

const CustomDrawer = styled(Drawer)(({ theme, open }) => ({
  width: open ? drawerWidth : collapsedDrawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: open ? drawerWidth : collapsedDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
}));

const TextWrapper = styled('div')<{ open: boolean }>(({ theme, open }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  opacity: open ? 1 : 0,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export default function DrawerLandscape() {

  const { state, dispatch } = React.useContext(Context) as TypeContext;
  const theme = useTheme();
  log.debug('DrawerLandscape');

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [selectedPath, setSelectedPath] = useState<string | null>(location.pathname);
  const [manualToggle, setManualToggle] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const anchorRef = React.useRef(null);

  useEffect(() => {
    setSelectedPath(location.pathname);
  }, [location.pathname]);

  const handleDrawerOpen = () => {
    if (!manualToggle) { // Only opens automatically if not in manual mode
      setOpen(true);
    }
  };

  const handleDrawerClose = () => {
    if (!manualToggle) { // Only closes automatically if not in manual mode
      setOpen(false);
    }
  };

  const toggleDrawer = () => {
    setManualToggle(true); // Activates manual mode
    setOpen(!open); // Changes the 'open' state
    setTimeout(() => setManualToggle(false), 300); // Deactivates manual mode after a short delay
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function navigate_icb() {
    let actionSetSelectedExercisePool: ActionSetSelectedExercisePool = { type: 'setSelectedExercisePool', pool: 'icb' }
    dispatch(actionSetSelectedExercisePool);
    let actionSetSelectedGroup: ActionSetSelectedGroup = { type: 'setSelectedGroup', group: state.allGroups.find(x => x.id === DEFAULT_GROUP)! }
    dispatch(actionSetSelectedGroup);
    //window.scrollTo(0, 0);

    //we use this to scroll so the search is hided behind the appbar. both ids are transparent dividers in Exercises.tsx
    const scrollToTopId = state.portrait ? 'initialViewExercisesPortrait' : 'initialViewExercisesLandscape';
    setTimeout(() => {
      const scrollToTop = document.getElementById(scrollToTopId);
      if (scrollToTop) {
        scrollToTop.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);

    navigate('/icb');
  }

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: open ? 'flex-end' : 'center', // Adjust this line
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  const firstSection: any[] = [
    {
      text: t('DrawerLandscape00'),
      icon: <HomeOutlinedIcon />,
      selectedIcon: <HomeRoundedIcon />,
      action: '/home',
      path: '/home',
      isVisible: true,
    },
    {
      text: t('DrawerLandscape01'),
      icon: <SportsBasketballOutlinedIcon />,
      selectedIcon: <SportsBasketballRoundedIcon />,
      action: () => navigate_icb(),
      path: '/icb',
      isVisible: true,
    },
    //CONCEPTS
    {
      text: t('DrawerLandscape13'),
      icon: <LightbulbOutlinedIcon />,
      selectedIcon: <LightbulbIcon />,
      action: '/concepts',
      path: '/concepts',
      isVisible: true,
    },
    {
      text: t('DrawerLandscape02'),
      icon: <FolderOpenOutlinedIcon />,
      selectedIcon: <FolderRoundedIcon />,
      action: '/practices',
      path: '/practices',
      isVisible: true,
    },
    {
      text: t('DrawerLandscape03'),
      icon: <InsertChartOutlinedIcon />,
      selectedIcon: <InsertChartIcon />,
      action: '/formgetpracticereport',
      path: '/formgetpracticereport',
      isVisible: true
    },
    {
      text: t('DrawerLandscape05'),
      icon: <SortByAlphaRoundedIcon />,
      selectedIcon: <SortByAlphaRoundedIcon />,
      action: '/groups',
      path: '/groups',
      isVisible: ['pro', 'clubadmin'].includes(state.user.data.attributes.field_user_type)
    },
    {
      text: t('DrawerLandscape10'),
      icon: <GroupWorkOutlinedIcon />,
      selectedIcon: <GroupWorkIcon />,
      action: '/myteams',
      path: '/myteams',
      isVisible: true,
    },
    {
      text: t('DrawerLandscape06'),
      icon: <GroupsOutlinedIcon />, // Replace with your desired icon
      selectedIcon: <Groups2 />,
      action: '/coaches',
      path: '/coaches',
      isVisible: state.user.data.attributes.field_user_type === 'clubadmin'
    },
    {
      text: t('DrawerLandscape11'),
      icon: <ArticleOutlinedIcon />, // Replace with your desired icon
      selectedIcon: <ArticleIcon />,
      action: '/clubdocuments',
      path: '/clubdocuments',
      isVisible: ['clubadmin', 'club'].includes(state.user.data.attributes.field_user_type)
    },
  ];

  const secondSection = [
    {
      path: '/profile',
      text: t('DrawerLandscape07'),
      icon: <AccountCircleOutlinedIcon />,
      selectedIcon: <AccountCircleRoundedIcon />,
      action: handleMenuOpen,
      isVisible: ['free', 'club', 'clubadmin', 'pro'].includes(state.user.data.attributes.field_user_type),
      menuItems: [
        {
          text: t('DrawerLandscape08'),
          action: () => {
            // window.open(`${BACKEND}/${formatLanguage(state.user.locale)}/user/${state.user.login.current_user.uid}/edit`);
            navigate('/profile')
            handleMenuClose();
          },
          isVisible: true,
          divider: true
        },
        {
          text: t('DrawerLandscape09'),
          action: () => {
            logout(state, dispatch, navigate);
            handleMenuClose();
          },
          isVisible: true,
          divider: false
        },
      ]
    },
  ];

  return (
    <CustomDrawer
      variant="permanent"
      onMouseEnter={handleDrawerOpen}
      onMouseLeave={handleDrawerClose}
      open={open}
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
    >
      <DrawerHeader>

        {open &&
          <>
            <Box ml={'3px'}>
              <img height={32} src={getImageURL(theme.palette.mode === 'dark' ? 'logoICBWhite.webp' : 'logoICBBlue.webp')} />
            </Box>
            <Typography component="div" sx={{ flexGrow: 1, ml: 1 }}>
              <Box component="span" sx={{ fontSize: '17px', fontWeight: 'bold', color: theme.palette.primary.main, fontStyle: 'italic', fontFamily: 'PT Sans, sans-serif' }}>i</Box>
              <Box component="span" sx={{ fontSize: '17px', fontWeight: 'bold', color: theme.palette.secondary.main, fontStyle: 'italic', fontFamily: 'PT Sans, sans-serif' }}>Coach</Box>
              <Box component="span" sx={{ fontSize: '17px', fontWeight: 'bold', color: theme.palette.primary.main, fontStyle: 'italic', fontFamily: 'PT Sans, sans-serif' }}>Basketball</Box>
            </Typography>
          </>
        }

        <IconButton
          onClick={toggleDrawer} // Use the toggleDrawer function to control the drawer
          aria-label="toggle drawer"
          sx={{ ...(open ? {} : { marginLeft: 'auto', marginRight: 'auto' }) }}
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>

      <Divider />

      {/* FIRST SECTION UPPER PART */}
      <Box style={{ flex: '1 0 auto' }}>
        <List>
          {firstSection.map((item, index) => (
            item.isVisible && (
              <ListItemButton
                key={index}
                onClick={() => {
                  typeof item.action === 'function' ? item.action() : navigate(item.action)
                }}
                selected={selectedPath === item.path}
                sx={{
                  //marginBottom: '10px',
                  backgroundColor: selectedPath === item.path ? 'transparent' : 'transparent',
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  },
                  '&.Mui-selected': {
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                  },
                }}
              >
                <ListItemIcon style={{ color: selectedPath === item.path ? '#DD6F20' : theme.palette.text.primary }} >
                  {selectedPath === item.path ? item.selectedIcon : item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={t(item.text)}
                  primaryTypographyProps={{
                    style: {
                      color: selectedPath === item.path ? '#DD6F20' : theme.palette.text.primary,
                      fontWeight: selectedPath === item.path ? 'bold' : 'normal',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontSize: '15px'
                    }
                  }}
                />
              </ListItemButton>
            )
          ))}
        </List>

        {/* CREATE DRILL BUTTON */}
        <Fab onClick={() => navigateExerciseCreate(state, dispatch, navigate)} style={{ background: '#DD6F20', height: '40px', width: '100%', color: 'white', borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <AddCircleOutlineOutlinedIcon />
          {open && (
            <Typography variant="body2" style={{
              marginLeft: '10px',
              whiteSpace: 'nowrap',
              //overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 'bold',
              textTransform: 'none'
            }}>
              {t('DrawerLandscape04')}
            </Typography>
          )}
        </Fab>
      </Box>

      {/* SECOND SECTION LOWER PART */}
      <Box style={{}}>
        <List>
          {secondSection.map((item, index) => (
            item.isVisible && (
              <ListItemButton
                key={index}
                onClick={handleMenuOpen}
              >
                <ListItemIcon style={{ color: selectedPath === item.path ? '#DD6F20' : theme.palette.text.primary }} >
                  {item.selectedIcon}
                </ListItemIcon>
                <TextWrapper open={open}>
                  <ListItemText primary={t(item.text)}
                    primaryTypographyProps={{
                      style: {
                        color: selectedPath === item.path ? '#DD6F20' : theme.palette.text.primary,
                        fontWeight: selectedPath === item.path ? 'bold' : 'normal',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '15px'
                      }
                    }}
                  />
                </TextWrapper>
              </ListItemButton>
            )
          ))}
        </List>
      </Box>

      <div ref={anchorRef} style={{ position: 'fixed', bottom: 0, left: 0 }} />

      <Menu
        // Use the ref as the anchorEl
        anchorEl={anchorRef.current}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {secondSection.find(item => item.action === handleMenuOpen)?.menuItems?.filter(x => x.isVisible).map((menuItem, index) => (
          <Box key={index}>
            <MenuItem onClick={() => {
              if (typeof menuItem.action === 'function') {
                menuItem.action();
              } else {
                navigate(menuItem.action);
              }
            }} style={{ height: '40px' /* or any other value */ }}>
              {menuItem.text}
            </MenuItem>
            {menuItem.divider && (
              <div style={{ height: '1px', backgroundColor: theme.palette.divider, width: '100%' }}></div>
            )}
          </Box>
        ))}
      </Menu>
    </CustomDrawer>
  );
}