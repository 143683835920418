import { Fragment, useContext, useEffect, useState } from "react";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import { Context } from "../App";
import { getActionSetConfirm, mapPractice, nodeCRUD, reportPracticeProgramDownload, reportPracticeProgramEmail } from "../misc/Functions";
import { ActionSetPractice, ActionShowPracticeProgram, CRUD, DataGridActions, DataGridUpdate, DrupalEntity, PracticeBetter, TypeContext, UserType } from "../misc/Types";
import { useNavigate } from "react-router-dom";
import { GridRowId } from "@mui/x-data-grid";
import CRUDList from "./CRUDList";
import DialogMyContacts from "./DialogMyContacts";
import DialogPracticeProgramPrint from "./DialogPracticeProgramPrint";

export default function Practices() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const navigate = useNavigate();
    log.debug(' Practices()');

    const [practiceID, setPracticeID] = useState('')
    const [openDialogMyContacts, setOpenDialogMyContacts] = useState(false)
    const [openDialogPracticeProgramPrint, setOpenDialogPracticeProgramPrint] = useState(false) // State to control the modal

    // const [rows, setRows] = useState<Array<any>>([])

    // Actions available in list depends on portrait/landscape and user type
    const actions = [DataGridActions.select, DataGridActions.delete, DataGridActions.mailTo]
        .concat(state.portrait ? [DataGridActions.print] : [DataGridActions.download])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    // Give running number to each practice. New count for each team
    function setPracticeNumber(practices: Array<any>) {
        if (practices.length === 0)
            return practices
        let team: string = practices[practices.length - 1].team
        let num: number = 1
        for (let i = practices.length - 1; i > -1; i--) {
            if (practices[i].team !== team) {
                num = 1
                team = practices[i].team
            }
            practices[i].pnum = num++;
        }
        return practices;
    }

    // Sort by team name and practice date reverse
    function compareFNTeamNamePracticeDate(a: PracticeBetter, b: PracticeBetter) {
        const aTeamName = a.team.attributes.title;
        const bTeamName = b.team.attributes.title;
        const aPracticeDate = a.date
        const bPracticeDate = b.date
        if (aTeamName < bTeamName)
            return -1
        else if (aTeamName > bTeamName)
            return 1
        else if (aPracticeDate < bPracticeDate)
            return 1
        else if (aPracticeDate > bPracticeDate)
            return -1
        else
            return 0
    }

    // Initial find all rows
    // useEffect(() => {
    //     const rowsLocal = setPracticeNumber(state.allPractices
    //         .filter(x => x.relationships.uid.data.id === state.user.data.id)
    //         .map(x => mapPractice(state, x))
    //         .sort(compareFNTeamNamePracticeDate)
    //         .map(x => {
    //             return {
    //                 id: x.practiceID,
    //                 pnum: 1,
    //                 team: x.team.attributes.title,
    //                 date: new Date(x.date).toLocaleString(state.user.locale.substring(0, 2), { dateStyle: 'short', timeStyle: 'short' }),
    //             }
    //         })
    //     )
    //     setRows(rowsLocal)
    // }, [state.allPractices, state.allTeams, state.allUsers])

    // Data columns
    const columns = () => [
        { field: 'pnum', headerName: '', maxWidth: 10 },
        { field: 'team', headerName: t('PracticeProgramForm22'), flex: 1 },
        { field: 'date', headerName: t('Generel06'), flex: 1 },
    ]

    function _processRowSelect(id: GridRowId) {
        // find practice to edit
        let practice = mapPractice(state, state.allPractices.find(x => x.id === id.toString())!)
        // clear praticeID so when user saves the practice a new practice is created
        practice.practiceID = ''
        let actionSetPractice: ActionSetPractice = { type: 'setPractice', practice: practice }
        dispatch(actionSetPractice);
        let actionShowPracticeProgram: ActionShowPracticeProgram = { type: 'showPracticeProgram', show: true }
        dispatch(actionShowPracticeProgram);
        navigate('/icb')
    }

    function processRowSelect(id: GridRowId) {
        // If practice is dirty the warning before select new practice
        if (state.curPractice.selectedExercises.length > 0 && state.curPractice.dirty) {//selectedExercises.length > 0 && state.practiceProgramDirty) {
            dispatch(getActionSetConfirm(t("Generel19"), 'OK', () => {
                _processRowSelect(id)
            }));
        } else {
            _processRowSelect(id)
        }
    }

    function _processRowPrint(id: GridRowId) {
        let actionSetPractice: ActionSetPractice = { type: 'setPractice', practice: mapPractice(state, state.allPractices.find(x => x.id === id)!) }
        dispatch(actionSetPractice);
        setOpenDialogPracticeProgramPrint(true)
    }

    function processRowPrint(id: GridRowId) {
        // If practice is dirty the warning before select new practice
        if (state.curPractice.selectedExercises.length > 0 && state.curPractice.dirty) {//selectedExercises.length > 0 && state.practiceProgramDirty) {
            dispatch(getActionSetConfirm(t("Generel19"), 'OK', () => {
                _processRowPrint(id)
            }));
        } else {
            _processRowPrint(id)
        }
    }

    // download practice PDF
    async function processRowDownload(id: GridRowId) {
        reportPracticeProgramDownload(state, dispatch, navigate, id.toString())
    }

    // email practice PDF to selected email address
    async function processRowMailTo(id: GridRowId) {
        if (state.user.data.attributes.field_user_type === UserType.free) {
            dispatch(getActionSetConfirm(t('AlertMsg39'), t('ICBAppBar04'), () => navigate('/setsubscription')));
        } else {
            setOpenDialogMyContacts(true)
            setPracticeID(id.toString())
        }
    }

    // Close dialog to do email selection
    function onCloseDialogMyContacts(send: boolean) {
        setOpenDialogMyContacts(false)
        reportPracticeProgramEmail(state, dispatch, practiceID, send)
    }

    function processRowUpdate() {
        return (dataGridUpdate: DataGridUpdate) =>
            new Promise((resolve) => {
                const practice: DrupalEntity = {
                    type: 'node--practice',
                    id: dataGridUpdate.id as string,
                }
                nodeCRUD(state, dispatch, CRUD.Delete, practice)
                resolve(dataGridUpdate.rows)
            })
    }

    return (
        <Fragment>
            <CRUDList
                // rows={rows}
                rows={
                    setPracticeNumber(state.allPractices
                        .filter(x => x.relationships.uid.data.id === state.user.data.id)
                        .map(x => mapPractice(state, x))
                        .sort(compareFNTeamNamePracticeDate)
                        .map(x => {
                            return {
                                id: x.practiceID,
                                pnum: 1,
                                team: x.team.attributes.title,
                                date: new Date(x.date).toLocaleString(state.user.locale.substring(0, 2), { dateStyle: 'short', timeStyle: 'short' }),
                            }
                        })
                    )
                }
                columns={columns()}
                actions={actions}
                processRowSelect={processRowSelect}
                processRowPrint={processRowPrint}
                processRowUpdate={processRowUpdate}
                processRowMailTo={processRowMailTo}
                processRowDownload={processRowDownload}
                dropDownFilterList={
                    state.allTeams
                        .filter(x => x.relationships.uid.data.id === state.user.data.id) // only show users own teams
                        .map(item => item.attributes.title)
                }
                dropDownFilterLabel={t('PracticeProgramForm22')}
                dropDownFilterColumn={'team'}
                gridColumnVisibilityModel={{ id: false, note: !state.portrait }}
                columnFocusOnNew="practice"
                deleteWarning={t('Practices03')}
            />

            <DialogMyContacts
                open={openDialogMyContacts}
                onClose={onCloseDialogMyContacts}
            />

            {/* Show practice in portrait mode */}
            {/* DialogPracticeProgramPrint can only be used if we have a current practice */}
            {
                state.curPractice.team.id &&
                <DialogPracticeProgramPrint
                    open={openDialogPracticeProgramPrint}
                    onClose={() => setOpenDialogPracticeProgramPrint(false)}
                />
            }

        </Fragment>
    )
}