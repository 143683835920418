import { useContext } from 'react';
import { Box, Fab, Badge } from '@mui/material';
import { useLocation, useNavigate, } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Context } from '../App';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { ActionShowPracticeProgram, TypeContext } from '../misc/Types';
import { Tooltip } from '@mui/material';

export default function OpenPracticeProgramButton() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();

    // Move the OpenPracticeProgram button a little up or dwon dependnig on
    // state.nativeApp because state.nativeApp also dictates the height of
    // the lower app bar and we want the button to always have the same distance
    // from the lower app bar
    const sxDistanceFromLowBar = () => state.nativeApp ? { xs: 90, sm: 100 } : { xs: 70, sm: 80 }

    return (
        <Box
            sx={{
                position: 'fixed',
                // bottom: state.portrait ? { xs: 90, sm: 100 } : 23,
                bottom: state.portrait ? sxDistanceFromLowBar : 23,
                right: state.portrait ? { xs: 13, sm: 21 } : 23,
                zIndex: 1100,
            }}>
            <Tooltip title={t('OpenPracticeProgramButton00')} placement="top">
                <Fab
                    aria-label="toggleShowPracticePlanner"
                    sx={{
                        width: state.portrait ? '56px' : '65px',
                        height: state.portrait ? '56px' : '65px',
                        backgroundColor: '#DD6F20',
                        '&:hover': {
                            backgroundColor: '#DD6F20',
                        }
                    }}
                    onClick={() => {
                        // user has clicked on the Practice Program floating button
                        // only toggle show of practice program if user is on /icb
                        // if user is on /home and user clicks the Practice Program floating button then
                        // the user just wants to go to /icb and show the proctice program if it was
                        // already shown
                        if (location.pathname === '/icb') {
                            let actionShowPracticeProgram: ActionShowPracticeProgram = { type: 'showPracticeProgram', show: !state.showPracticeProgram };
                            dispatch(actionShowPracticeProgram);
                        } else {
                            navigate('/icb');
                        }
                    }}
                >
                    <Badge
                        badgeContent={state.curPractice.selectedExercises.length}//selectedExercises.length}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: '#00398F', // Custom background color
                                color: 'white', // Custom text color
                                border: '1px solid white' // Add your border color here
                            }
                        }}>
                        <PendingActionsIcon style={{
                            fontSize: state.portrait ? 25 : 30,
                            color: 'white'
                        }}
                        />
                    </Badge>
                </Fab>
            </Tooltip>
        </Box>
    );
}