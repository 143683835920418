import Grid2 from '@mui/material/Unstable_Grid2';
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react"
import { Box, Button, CircularProgress, Divider, Typography, } from '@mui/material';
import { DrupalEntity, TypeContext } from '../misc/Types';
import { Context } from '../App';
import ExerciseCard from './ExerciseCard';
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { LockOpen } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import SearchField from './SearchField';

export default function Exercises() {
  const { state } = useContext(Context) as TypeContext;
  const navigate = useNavigate();
  const { t } = useTranslation();
  log.debug('Exercises()');
  const theme = useTheme();

  // infinite scrolling for exercise list. See links below
  // https://www.youtube.com/watch?v=NZKUirTtxcg
  // https://github.com/WebDevSimplified/React-Infinite-Scrolling
  // https://claritydev.net/blog/typescript-typing-react-useref-hook
  const pageSize = 10; // render exercises in blocks of "pageSize"
  const [pageNumber, setPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const observer = useRef<IntersectionObserver | null>(null);
  const lastExerciseElementRef = useCallback((node: any) => {
    // console.log('lastExerciseElementRef = useCallback');
    // console.log(node);
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        // console.log('lastExerciseElementRef, last element on display!');
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) observer.current.observe(node);
  }, [])

  useEffect(() => {
    // console.log('PAGENUMBER, useEffect set value of pageNumber to 1, previous value: ', pageNumber);
    setPageNumber(1);
  }, [state.selectedGroup, state.selectedExercisePool])

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  // Exercises in selected group and selected pool
  const exercisesOrig = () => {
    // return if no groups have been retrieved or no group selected
    if (state.allGroups.length === 0 || !state.selectedGroup?.attributes?.title)
      return (<></>)

    let exer = state.allExercises.filter(x =>
      // do not show discontinued exercises
      !x.attributes.field_discontinued &&
      // exercise must belong to selected exercise group or no group for 'show all'
      (!state.selectedGroup.id || x.relationships.field_group.data.id === state.selectedGroup.id) &&
      (
        // exercise must belong to selected exercise pool, icb, mine or club
        // icb
        // exercise owner must uid = 7 - icoachbasketball.com
        (state.selectedExercisePool === 'icb' && x.relationships.uid.data.meta.drupal_internal__target_id === 7) ||
        // mine
        // exercise owner must be current user or current user must have exercise in his/her favorite list
        (state.selectedExercisePool === 'mine' &&
          (x.relationships.uid.data.id === state.user.data.id ||
            state.user.data.relationships.field_favorite_exercises.data.find((y: DrupalEntity) => y.id === x.id))) ||
        // club
        // If current user is club user then exercise owner has to be current users clubadmin
        // If current user is clubadmin user then exercise owner has to be current user
        (state.selectedExercisePool === 'club' && x.relationships.uid.data.id ===
          (state.user.data.attributes.field_user_type === 'club' ?
            state.user.data.relationships.field_my_club_admin.data.id :
            state.user.data.id))
      )
    )

    // Filter exercises based on search query
    if (searchQuery) {
      exer = exer.filter(x => x.attributes.title.toLowerCase().includes(searchQuery.toLowerCase()));
    }

    // If no exercises available, return a button with the message
    if (exer.length === 0) {
      return (

        <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', textAlign: 'center' }}>
          <Box display={state.exercisesRetrivedInitially ? 'none' : 'block'} marginTop={2}>
            <CircularProgress size={25} />
          </Box>
          <Typography sx={{ paddingTop: 2, color: theme.palette.text.secondary, fontSize: { xs: 13, sm: 16 } }}>
            {state.exercisesRetrivedInitially ? t('Exercises04') : t('Exercises07')}
          </Typography>
        </Box>

      );
    }

    // infinite scrolling
    return (
      // extract the pageNumber * pageSize first elements and put a ref on the last element
      exer.splice(0, pageNumber * pageSize).map((exercise: DrupalEntity, index: number) => {
        if (index === pageNumber * pageSize - 1) {
          return (<Grid2 key={exercise.id} xs={6} sm={4} md={4} xl={3} ref={lastExerciseElementRef}><ExerciseCard exercise={exercise} /></Grid2>)
        } else {
          return (<Grid2 key={exercise.id} xs={6} sm={4} md={4} xl={3} ><ExerciseCard exercise={exercise} /></Grid2>)
        }
      })
    )
  }

  return (
    <Box style={{ marginBottom: 150 }}>

      {/* WE USE THIS 3 BOXES BECAUSE IN THIS WAY WE CAN HIDE THE SEARCHFIELD BELOW THE ICBAPPBAR.
      WE START THE VIEW ON THE DIVIDER WITH THE ID= initialViewExercises */}
      <Divider sx={{ borderColor: 'transparent' }} id="initialViewExercisesLandscape" />
      <Box style={{ marginTop: 57 }} />
      <Divider sx={{ borderColor: 'transparent' }} id="initialViewExercisesPortrait" />
      <Box style={{ marginTop: 58 }} />

      {/* Search Field */}

      <Box width="95%" maxWidth="850px" margin={'auto'}>
        <SearchField onSearch={handleSearch} />
      </Box>

      {/* Exercises in selected group and selected pool */}
      <Grid2 container margin={state.portrait ? '12px' : 0} spacing={state.portrait ? 1 : 3}>
        {exercisesOrig()}
      </Grid2>
      {
        state.user.data.attributes.field_user_type === 'free' && state.selectedExercisePool === "icb" &&
        <Fragment>
          <Box display="flex" justifyContent="center" width="100%">
            <Button
              onClick={() => navigate('/setsubscription')}
              variant="contained"
              color="secondary"
              style={{ textTransform: 'none', fontWeight: 'bold' }}
            >
              <LockOpen style={{ marginRight: 10 }} />
              {t('Generel16')}
            </Button>
          </Box>
        </Fragment>
      }
    </Box >
  );
}