import { Box, CardMedia, Fab, Paper, Typography, useTheme } from '@mui/material';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { CRUD, DrupalEntity, JSONAPITypeId, PropsExerciseCard, TypeContext } from '../misc/Types';
import { Context } from '../App';
import { exerciseImage, getActionSetConfirm, inFavorites, nodeCRUD } from '../misc/Functions';
import { CDN } from '../misc/Constants';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import EditIcon from '@mui/icons-material/Edit';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useTranslation } from 'react-i18next';
import log from "../misc/Logger";
import { DialogExerciseDetails } from './DialogExerciseDetails';
import DialogExerciseDurationAndFocus from './DialogExerciseDurationAndFocus';
import { DialogShowVideo } from './DialogShowVideo';
import { Tooltip } from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FiberNewRoundedIcon from '@mui/icons-material/FiberNewRounded';

export default function ExerciseCard(props: PropsExerciseCard) {
  const { state, dispatch } = useContext(Context) as TypeContext;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const [openDialogExerciseDetails, setOpenDialogExerciseDetails] = useState(false)
  const [openDialogExerciseDurationAndFocus, setOpenDialogExerciseDurationAndFocus] = useState(false);
  const [conceptFileID, setConceptFileID] = useState('') // Controls open/close of DialogShowVideo
  const [concept, setConcept] = useState('') // Title for video dialog

  // Value for repostion of video play icon 
  // const [videoPlayIconLeft, setVideoPlayIconLeft] = useState(0);
  // Initially show exercise description. In portrait mode there is an option not to show exercise description
  const [showExerciseDescription, setShowExerciseDescription] = useState(false);
  const [showConcepts, setShowConcepts] = useState(false);
  const [disabled, setDisabled] = useState(false)
  const [imageLoadTime, setImageLoadTime] = useState(new Date().getTime())
  const imageRef = useRef<HTMLImageElement>(null);
  const fileIDVideo = props.exercise.relationships.field_exercise_video.data?.id;
  const fileIDImage = props.exercise.relationships.field_exercise_board_image.data?.id;

  // useEffect(() => {
  //   console.log('exercisecard, useEffect. Files:', state.allFiles.length)
  // }, [state.allFiles])

  // Exercise author ID comes from the original author relation on the exercise if available, Otherwise, exercise author ID comes from the exercise owner
  const exerciseAuthorID = (props.exercise.relationships.field_original_author.data && props.exercise.relationships.field_original_author.data.id) || props.exercise.relationships.uid.data.id;
  // Fall back on current user. Ie. when the original author has left the club - the current user no longer has access to the original author
  const exerciseAuthor = state.allUsers.find(x => x.id === exerciseAuthorID) || state.user.data;
  // Find URL for author image
  const fileIDAuthorImage = exerciseAuthor.relationships.user_picture.data?.id;
  const authorImage = fileIDAuthorImage && state.allFiles.find(x => x.id === fileIDAuthorImage)?.attributes.uri.url;

  // Anonymous is not allowed to see field_first_name and field_last_name. Use displayname instead!
  let authorName = '';
  if (state.user.login.current_user.name === 'Anonymous')
    authorName = exerciseAuthor.attributes.display_name;
  else
    authorName = `${exerciseAuthor?.attributes?.field_first_name} ${exerciseAuthor?.attributes?.field_last_name}`;

  // // Dynamically find position of video play icon within exercise image. 
  // // See https://medium.com/@stheodorejohn/resize-and-scroll-event-listeners-in-react-enhancing-dynamic-user-experiences-6cdfa0074e2f
  // useEffect(() => {
  //   const handleResize = () => {
  //     setVideoPlayIconLeft(imageRef.current!.offsetWidth - 36);
  //   };
  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  // // Set initial value for repostion of video play icon
  // useEffect(() => {
  //   setVideoPlayIconLeft(imageRef.current!.offsetWidth - 36)
  // }, [imageRef.current, state.showPracticeProgram])

  // Favorite has been flopped. Reenable favorites button
  useEffect(() => {
    setDisabled(false)
  }, [state.user.data])

  async function clickFavoriteIcon() {
    if (state.user.login.current_user.uid === 0) {
      // Anonymous user tries to flip favorite
      dispatch(getActionSetConfirm(t('Generel00'), t('AccountCaptcha08'), () => navigate('/signup')));
    } else {
      setDisabled(true)

      // log shows erorr that I think comes from setting favorite. Check log entry
      // Jan  9 23:40:33 linode2 drupalICBProd: https://data.icoachbasketball.com|1704840033|client error|2806:103e:21:4930:98df:3bde:9f31:5f88|https://data.icoachbasketball.com/hidden/493443093fc62c86c08039c627ee925d/user/user/804d6b32-5f21-43d6-b93b-24cd7a3b901d|https://app.i>
      // For we add log statement to see if error does come from setting favorite
      log.debug('ExerciseCard(), clickFavoriteIcon');

      // There is a risk that the user does not have access to the exercise that he/she
      // flags as an exercise. Ie. user logs in as member of a club and user is asssigned
      // to a new club in the backend and user does not refresh. Then user can flag an
      // exercise he no longer has access to. We live with that risk. We don't want to 
      // look up if user has access due to performance.

      // Find new list of favorites
      const field_favorite_exercises = (state.user.data.relationships.field_favorite_exercises.data.find((x: JSONAPITypeId) => x.id === props.exercise.id))
        ? state.user.data.relationships.field_favorite_exercises.data.filter((x: JSONAPITypeId) => x.id !== props.exercise.id)
        : state.user.data.relationships.field_favorite_exercises.data.concat({ type: 'node--exercise', id: props.exercise.id })

      // Find new user data
      let userData: DrupalEntity = {
        type: 'user--user',
        id: state.user.data.id,
        relationships: {
          field_favorite_exercises: { data: field_favorite_exercises.length === 0 ? null : field_favorite_exercises },
        }
      }

      // Save new user data
      const resp = await nodeCRUD(state, dispatch, CRUD.Update, userData)
      if (!resp.data) {
        dispatch(getActionSetConfirm(resp));
        return
      }
    }
  }

  // Set flag on new exercises. Determined by configuration
  const newExercise = () => {
    let nowMinusXDays = Date.now() - parseInt(state.configuration[0].attributes.field_days_to_flag_new_exercises) * 24 * 60 * 60 * 1000;
    return (Date.parse(props.exercise.attributes.created) > nowMinusXDays || Date.parse(props.exercise.attributes.changed) > nowMinusXDays)
  }

  // Set flag on new concept. Determined by configuration
  function newConcept(concept: DrupalEntity): boolean {
    let nowMinusXDays = Date.now() - parseInt(state.configuration[0].attributes.field_days_to_flag_new_concept) * 24 * 60 * 60 * 1000;
    return (Date.parse(concept.attributes.created) > nowMinusXDays || Date.parse(props.exercise.attributes.changed) > nowMinusXDays)
  }

  return (
    <Fragment>

      <DialogExerciseDetails
        open={openDialogExerciseDetails}
        onClose={() => setOpenDialogExerciseDetails(false)}
        exercise={props.exercise}
        addOption={true}
      />

      <DialogExerciseDurationAndFocus
        open={openDialogExerciseDurationAndFocus}
        onClose={() => setOpenDialogExerciseDurationAndFocus(false)}
        exercise={props.exercise}
      />

      <DialogShowVideo
        open={conceptFileID !== ''}
        onClose={() => setConceptFileID('')}
        fileIDVideo={conceptFileID}
        concept={concept}
      />

      <Paper
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '350px',
          margin: 'auto',
          padding: state.portrait ? 1 : 1.5,
          borderRadius: state.portrait ? 2 : 5,
        }}
      >

        <Box display={'flex'}>
          {/* Author image */}
          <Box marginRight={0.5}>
            {
              authorImage ?
                <img
                  src={`${CDN}${authorImage}.webp`}
                  height="32"
                  width="32"
                  style={{ borderRadius: "50%", height: '32px', width: '32px' }}
                  alt="Imagen del Autor"
                />
                : <AccountCircleRoundedIcon />

            }
          </Box>
          {/* Author */}
          <Typography sx={{ flexGrow: 1, fontSize: { xs: 11, sm: 15 } }} marginTop={'auto'} marginBottom={'auto'}>
            {authorName}
          </Typography>

          {/* New exercise */}
          <Box marginTop={'auto'} marginBottom={'auto'}>
            {
              newExercise() ?
                <FiberNewRoundedIcon color={'secondary'} />
                : <></>
            }
          </Box>
        </Box>

        {/* Image. If video available and hover then show video */}
        <Box position='relative'>
          <CardMedia
            sx={{ display: 'block', maxHeight: '300px', objectFit: 'contain', margin: 'auto', borderRadius: 2 }}
            component='img'
            src={exerciseImage(state, fileIDImage, props.exercise.id)}
            // If image is not jet on CDN then retry in a 5s.
            onError={() => {
              if (state.exerciseIDLastCRUD === props.exercise.id) {
                const waitTime = new Date().getTime() - imageLoadTime
                log.info(`reload image (${waitTime}ms) : ${exerciseImage(state, fileIDImage)}`);
                if (waitTime > 30000) {
                  setImageLoadTime(Number.MAX_SAFE_INTEGER) // log error only once
                  log.error(`Error reload image (${waitTime}ms) : ${exerciseImage(state, fileIDImage)}`);
                }
                setTimeout(() => {
                  const src = exerciseImage(state, fileIDImage, props.exercise.id);
                  if (imageRef && imageRef.current && imageRef.current.src)
                    imageRef.current.src = src;
                }, 5000)
              }
            }}
            alt={`${t('ExerciseCard05')} ${state.exerciseIDLastCRUD === props.exercise.id ? t('ExerciseCard06') : ''}`}
            ref={imageRef}
            onClick={() => setOpenDialogExerciseDetails(true)}
          />
          {/* reposition video play icon 36px becuase it fits well with icon size of 24x24, ie PlayCircleOutlineIcon */}
          {/* <PlayCircleOutlineIcon sx={{ display: fileIDVideo ? 'block' : 'none', position: 'relative', top: '-36px', left: `${videoPlayIconLeft}px` }} /> */}
          <PlayCircleOutlineIcon sx={{ display: fileIDVideo ? 'block' : 'none', position: 'absolute', bottom: '4px', left: '4px' }} />
          <Tooltip title="Add drill to the Practice Planner" enterDelay={1000}>
            <Fab
              color="secondary"
              size="small"
              sx={{
                position: 'absolute',
                bottom: '4px',
                right: '4px',
                opacity: 0.7, // para añadir transparencia
                transition: 'opacity 0.3s',
                '&:hover': {
                  opacity: 0.9,
                  backgroundColor: '#DD6F20',
                  color: 'white',
                },
              }}
              onClick={() => setOpenDialogExerciseDurationAndFocus(true)}
            >
              <AddRoundedIcon />
            </Fab>
          </Tooltip>
        </Box>

        {/* Use marginTop -2 because we pull text up into area where video play icon is before icon is repositioned */}
        <Box sx={{ display: 'flex', marginTop: 1 }}>

          {/* Edit icon */}
          {
            props.exercise.relationships.uid.data.id === state.user.data.id &&
            <IconButton size='small' onClick={() => { navigate("/exercisecreate", { state: props.exercise }); }}>
              <EditIcon />
            </IconButton>
          }

          {/* Favorite icon */}
          {
            props.exercise.relationships.uid.data.id !== state.user.data.id &&
            <IconButton
              aria-label='flopFavorite'
              size='small'
              disabled={disabled}
              onClick={() => clickFavoriteIcon()}
            >
              {inFavorites(state, props.exercise.id!) ? <FavoriteIcon style={{ color: 'red' }} /> : <FavoriteBorderIcon />}
            </IconButton>
          }

          {/* Exercise Title. Use 'auto' on top and buttom margin to center text vertically */}
          <Typography sx={{ fontSize: { xs: 11, sm: 14 }, lineHeight: 1, fontWeight: 'bold', marginTop: 'auto', marginBottom: 'auto', width: 1 }} >
            {props.exercise.attributes.title}
          </Typography>

          {/* More icon to show concepts if there are any */}
          {
            props.exercise.relationships.field_concepts.data.length > 0 && (
              <Tooltip title={t('ExerciseCard11')}>
                <IconButton size='small' onClick={() => setShowConcepts(!showConcepts)}> {/* Cambiar a showConcepts */}
                  {showConcepts ? <TipsAndUpdatesIcon fontSize='small' color='primary' /> : <TipsAndUpdatesOutlinedIcon fontSize='small' />}
                </IconButton>
              </Tooltip>
            )
          }

        </Box>

        {!state.portrait && (
          <>
            <Typography sx={{ fontSize: { xs: 11, sm: 13 }, lineHeight: 1.4, whiteSpace: 'pre-wrap' }} marginLeft={0.5}>
              {showExerciseDescription
                ? props.exercise.attributes.field_description
                : props.exercise.attributes.field_description?.substring(0, 150) + (props.exercise.attributes.field_description?.length > 150 ? '...' : '')
              }
            </Typography>
            {props.exercise.attributes.field_description?.length > 150 && (
              <Typography
                onClick={() => setShowExerciseDescription(!showExerciseDescription)}
                sx={{
                  textTransform: 'none',
                  paddingLeft: 0.5,
                  paddingBottom: 1,
                  fontSize: '12px',
                  cursor: 'pointer',
                  color: theme.palette.text.secondary,
                  textDecoration: 'underline',
                }}
              >
                {showExerciseDescription ? t('ExerciseCard10') : t('ExerciseCard09')}
              </Typography>
            )}
          </>
        )}

        {/* Concept. Show buttons for each exercise concept */}
        {showConcepts && (
          <Box display="flex" justifyContent="center" flexWrap="wrap" gap={0.5} marginTop={1}>
            {
              props.exercise.relationships.field_concepts.data.map((conceptRef: JSONAPITypeId) => (state.allConcepts.find(x => x.id === conceptRef.id))).map((concept: DrupalEntity, index: number) => (
                <Fragment key={index}>
                  <Tooltip
                    title={newConcept(concept) ? `${t('ExerciseCard07')} "${concept.attributes.title}" ${t('ExerciseCard08')}` : ''}
                    enterDelay={500}
                  >
                    <Typography
                      variant='body2'
                      sx={{
                        fontSize: { xs: 9, sm: 10 },
                        textTransform: 'none',
                        fontWeight: 'bold',
                        borderRadius: '20px',
                        cursor: 'pointer',
                        padding: '2px 6px',
                        transition: 'opacity 0.3s',
                        //border: '1px solid #eeb68e',
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.background.paper,
                        opacity: 0.8, // para añadir transparencia
                        //border: newConcept(concept) ? '1px solid #eeb68e' : `1px solid ${theme.palette.divider}`,
                        '&:hover': {
                          backgroundColor: theme.palette.primary.main,
                          opacity: 1,
                        }
                      }}
                      onClick={() => {
                        setConceptFileID(concept.relationships.field_concept_video.data.id)
                        setConcept(concept.attributes.title)
                        log.info('DialogShowVideo() - ' + concept.attributes.title);
                      }}
                    >
                      {concept.attributes.title}
                    </Typography>
                  </Tooltip>
                </Fragment>
              ))
            }
          </Box>
        )}

        {/* Create flexbox item that will take up as much space as possible. Thereby pressing the following flexbox item all the way down */}
        <Box height={1}></Box>

        {/* {
          (!state.portrait || showExerciseDescription) && (
            <Divider sx={{ margin: '5px 0' }} />
          )
        } */}

        {/* Add exercise to practice by open dialog and press button */}
        {/* <Box display={'flex'} width={1} sx={{ justifyContent: 'center' }} marginTop={state.portrait && !showExerciseDescription ? 0 : 1} marginBottom={state.portrait ? 1 : 0}>
          <Button
            aria-label={`exercisecard_addtopractice_${props.exercise.attributes.title.replaceAll(' ', '_')}`}
            variant='outlined'
            size='small'
            sx={{
              borderRadius: '20px',
              color: '#DD6F20',
              borderColor: '#DD6F20',
              '&:hover': {
                borderColor: theme.palette.secondary.main,
              },
            }}
            onClick={() => setOpenDialogExerciseDurationAndFocus(true)}
          >
            <Typography sx={{ fontSize: { xs: 10, sm: 12 } }}>
              {t('ExerciseCard00')}
            </Typography>
          </Button>
        </Box> */}

      </Paper>
    </Fragment >
  );
}
