import { Menu, MenuItem } from "@mui/material";
import { TypeContext } from "../misc/Types";
import { useContext } from "react";
import { Context } from "../App";
import { useNavigate } from "react-router-dom";
import { logout } from "../misc/Functions";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";

export interface PropsMenuMain {
    open: boolean,
    onClose: () => any,             // child calls fct in parent
    anchorEl: null | HTMLElement,   // where to popup the menu 
}

export function MenuMain(props: PropsMenuMain) {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { open, onClose, anchorEl } = props;
    const theme = useTheme();

    const menuItems = [
        //LIST OF CONCEPTS
        {
            label: t('DrawerLandscape13'),
            onClick: () => {
                navigate('/concepts');
                onClose();
            },
            //condition: ![UserType.free].includes(state.user.data.attributes.field_user_type),
            divider: true
        },
        // CREATE REPORT
        {
            label: t('ICBAppBar01'),
            onClick: () => {
                navigate('/formgetpracticereport');
                onClose();
            },
            //condition: ![UserType.free].includes(state.user.data.attributes.field_user_type),
            divider: true
        },
        // MY TEAMS
        {
            label: t('DrawerLandscape10'),
            onClick: () => {
                navigate('/myteams');
                onClose();
            },
            divider: true
        },
        // CLUB ADMIN SEND INVITE - not for now
        // {
        //     label: t('ICBAppBar11'),
        //     onClick: () => {
        //         navigate('/sendclubmemberinvitation');
        //         onClose();
        //     },
        //     condition: state.user.data.attributes.field_user_type === 'clubadmin',
        //     divider: true
        // },
        // GROUPS
        {
            label: t('ICBAppBar10'),
            onClick: () => {
                navigate('/groups');
                onClose();
            },
            condition: ['pro', 'clubadmin'].includes(state.user.data.attributes.field_user_type),
            divider: true
        },
        // SET SUBSCRIPTION - we now do it from My Account
        // {
        //     label: t('ICBAppBar04'),
        //     onClick: () => {
        //         navigate('/setsubscription');
        //         onClose();
        //     },
        //     divider: true
        // },
        // CLUB DOCUMENTS
        {
            label: t('DrawerLandscape11'),
            onClick: () => {
                navigate('/clubdocuments');
                onClose();
            },
            condition: ['clubadmin', 'club'].includes(state.user.data.attributes.field_user_type),
            divider: true
        },
        // COACHES
        {
            label: t('ICBAppBar03'),
            onClick: () => {
                navigate('/coaches');
                onClose();
            },
            condition: state.user.data.attributes.field_user_type === 'clubadmin',
            divider: true
        },
        //DRUPAL PROFILE
        {
            label: t('DrawerLandscape07'),
            onClick: () => {
                navigate('/profile');
                onClose();
            },
            divider: true
        },
        // LOGOUT
        {
            label: t('ICBAppBar06'),
            onClick: () => {
                onClose();
                logout(state, dispatch, navigate);
            },
            divider: false
        },
    ];

    return (
        <Menu
            id="dropDownMenuActions"
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
        >
            {menuItems.map((item, index) => item.condition !== false && (
                <div key={index} style={{ width: '100%' }}>
                    <MenuItem onClick={item.onClick} sx={{ height: '32px' }}>
                        {item.label}
                    </MenuItem>
                    {item.divider && (
                        <div style={{ height: '1px', backgroundColor: theme.palette.divider, width: '100%' }}></div>
                    )}
                </div>
            ))}
        </Menu>
    );
}