// // import ReactDOM from 'react-dom/client'
// import App from './App'
// import './index.css'
// import { BrowserRouter } from 'react-router-dom'
// import { ThemeContextProvider } from './theme/ThemeContextProvider'

// ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
//   <>
//     {/* <React.StrictMode> */}
//     <ThemeContextProvider>
//       <BrowserRouter basename={import.meta.env.BASE_URL}>
//         <App />
//       </BrowserRouter>
//     </ThemeContextProvider>
//     {/* </React.StrictMode> */}
//   </>
// )


// import React from 'react'
// import ReactDOM from 'react-dom/client'
// import App from './App.tsx'
// import './index.css'

// ReactDOM.createRoot(document.getElementById('root')!).render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
// )



import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
// import React from 'react'
import { ThemeContextProvider } from './theme/ThemeContextProvider.tsx'

ReactDOM.createRoot(document.getElementById('root')!).render(

  <ThemeContextProvider>
    {/* <React.StrictMode> */}
    <BrowserRouter basename={import.meta.env.BASE_URL}>
      <App />
    </BrowserRouter>
    {/* </React.StrictMode> */}
  </ThemeContextProvider>
)
