import { AppBar, Box, Button, Divider, Toolbar, Tooltip, Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import LanguageICB from "./LanguageICB";
import { getImageURL, landingPage } from "../misc/Functions";
import { Context } from "../App";
import { TypeContext } from "../misc/Types";
import { useTheme } from "@mui/material";

// Simple page to utilize PWA facilities on Android
export default function InstallAndroid() {

    // const navigate = useNavigate();
    const { t } = useTranslation();
    const { state } = useContext(Context) as TypeContext;
    const theme = useTheme();

    return (
        <Box marginTop={'70px'} marginBottom={'70px'}>
            <AppBar position="fixed">
                <Toolbar>
                    <Box display="flex" justifyContent="space-between" width="100%">
                        <Tooltip title={'Home'}>
                            <Button onClick={() => {
                                if (!state.nativeApp)
                                    window.location.href = landingPage()
                            }}>
                                <img width={40} height={'auto'} src={getImageURL('logoICBWhite.webp')} style={{ borderRadius: "50%" }} />
                            </Button>
                        </Tooltip>
                        <Box display="flex" alignItems="center">
                            <LanguageICB color={'white'} variant="outlined" />
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>

            {/* IF THE INSTALL BUTTON SHOW, CLICK DOWNLOAD */}
            <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
                <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                    {t('InstallAndroid00')}
                </Typography>
                <Divider />
                <Typography sx={{ marginTop: 3, textTransform: 'none', fontSize: { xs: '15 px', sm: '18px' }, fontWeight: 'bold', color: theme.palette.primary.main }}>
                    {t('InstallAndroid01')}
                </Typography>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                    <img src="/images/pwa/screenshots/InstallAndroid00.png.webp" alt="Descripción de la imagen 1" style={{ width: '95%', maxWidth: '350px', height: 'auto', border: '2px solid #00398F', borderRadius: 8 }} />
                </Box>
            </Box>

            {/* IF THE INSTALL DOES NOT SHOW, FOLLOW THE PROCESS */}
            <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 4 }}>
                <Divider />
                <Typography sx={{ marginTop: 3, textTransform: 'none', fontSize: { xs: '15 px', sm: '18px' }, fontWeight: 'bold', color: theme.palette.secondary.main }}>
                    {t('InstallAndroid02')}
                </Typography>
                <Typography sx={{ marginTop: 1, fontSize: { xs: '12 px', sm: '15px' }, color: theme.palette.secondary.main }}>
                    {t('InstallAndroid03')}
                </Typography>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                    <img src="/images/pwa/screenshots/InstallAndroid01.png.webp" alt="Descripción de la imagen 1" style={{ width: '95%', maxWidth: '350px', height: 'auto', border: '2px solid lightgrey', borderRadius: 8 }} />
                </Box>
                <Typography sx={{ marginTop: 1, fontSize: { xs: '12 px', sm: '15px' }, color: theme.palette.secondary.main }}>
                    {t('InstallAndroid04')}
                </Typography>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                    <img src="/images/pwa/screenshots/InstallAndroid02.png.webp" alt="Descripción de la imagen 1" style={{ width: '95%', maxWidth: '350px', height: 'auto' }} />
                </Box>
            </Box>
        </Box >
    );
}
