import { createTheme } from "@mui/material";
import { DrupalEntity, UISettings, TypeState, UserInfo, PracticeBetter } from "../misc/Types";
import { testEnvironment, } from "./Functions";
import getUserLocale from 'get-user-locale';

export const NATIVEAPPID = 'MDlhYzcxYjEwN2M5Njg1NjlhOGU2ZjUzYTM3NjYzZWU5NmJjODIyMGI0YmQ0Y2Vl'

// Pages/routes with no DrawerLandscape and no ICBAppBar
export const NAKEDPAES = ['', '/', '/print', '/termsandconditions', '/installandroid']

export const USEATTENDANCELIST = true;

console.log('CONSTANTS: ', getUserLocale())

export const IMAGEMIMETYPES = ['image/apng', 'image/avif', 'image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/webp']
export const VIDEOMIMETYPES = ['video/mp4', 'video/webm', 'video/ts', 'video/mov', 'video/quicktime']

export const DRUPALENTITYINIT: DrupalEntity = {
  type: '',
  id: '',
  attributes: {},
  relationships: {},
}

export const PRACTICEINIT: PracticeBetter = {date: new Date(), note: '', selectedExercises: [], attendance: [], dirty: false, team: DRUPALENTITYINIT, uid: ''}

export const USERINIT: UserInfo = {
  // Next lines. Set default to 'Anonymous' and not blank. Anonymous allow run test
  // It seems that if default value is Anonymous the a regular user can not log in!!!!
  login: { "current_user": { "uid": 0, "name": 'Anonymous' }, "csrf_token": '', "logout_token": '' },
  data: DRUPALENTITYINIT,
  locale: getUserLocale() === 'es-ES' ? 'esES' : 'enUS'
}

export const UISETTINGSINIT: UISettings = {
  showAttendance: false,  // start of false because then we open if user has that setting. If we start out open and user has setting closed then we shortly see values that later goes away and that is bad UI
}

export const STATEINIT: TypeState = {
  loggedIn: -1,
  user: USERINIT,
  // selectedExercises: [],
  selectedExercisePool: 'icb',
  allFiles: [],
  allUsers: [],
  allGroups: [],
  allExercises: [],
  allPractices: [],
  allPlayers: [],
  allTeams: [],
  allConcepts: [],
  curPractice: PRACTICEINIT,
  configuration: [],
  backdrop: 0,                    // show spinner intially
  debug: false,
  logLevel: 'info',
  showPracticeProgram: false,
  portrait: false,
  showWelcomePresentation: false, // Start out false for cleaner UI. Ie. show and automatically not show!
  gotUsersGroupsExercises: 0,
  // oneTimeExecuted: false,
  showIntro: true,
  confirm: { code: () => { } },      // Dumb confirmation structure that has no effect!
  selectedGroup: DRUPALENTITYINIT,
  nativeApp: false,
  fromApp: '',
  // disabled: false,
  exercisesRetrivedInitially: true,          // TO DO consider delete this state var
  fetchController: new AbortController(),
  awaitPaymentProcessing: false,
  uiSettings: UISETTINGSINIT,
  // intervalIDParsePractices: 0,
  // counterParseExercises: 0,
  practicesRetrieved: false,
}

export const SITE_NAME = "iCoachBasketball.com";
export const JSONAPIPATH = "hidden/493443093fc62c86c08039c627ee925d"
export var BACKEND = "https://data.icoachbasketball.com";
if (testEnvironment())
  BACKEND = "https://test.data.icoachbasketball.netmaster.dk";
export const CDN = `https://icbcdn.dk/icb/var/www/${BACKEND.split('//')[1]}/web`;

export const HEADERS_GET: RequestInit = {
  credentials: "include", // Always send user credentials (cookies, basic http auth, etc..), even for cross-origin calls.
}

export const LPTHEME = createTheme({
  palette: {
    primary: {
      main: '#15468f', // ICB Blue
    },
    secondary: {
      main: '#d47029', // ICB Orange
    },
  },
});

export const GREYBACKGROUND = 100

export const NEWS_EXERCISE_DAYS_LOOK_BACK = 0

export const DEFAULT_GROUP = 'f6d36ff5-c838-45b7-b0c9-94e630ba9177'; // ball handling

/*
When the back end runs operation "create_checkout_session" then the backend creates a random
string and stores the random string on the users profile.
The random string is returned to the app after successfull Stripe payment using query string parameter 
ICB_STRIPE_QUERY_PARAMETER.
At app start the app looks for two query string parameters ICB_STRIPE_QUERY_PARAMETER 
and ICB_BODY_SET_SUBSCRIPTION_PARAMETER. If the two query string parameters are available
then the JSON structure in query string parameter ICB_BODY_SET_SUBSCRIPTION_PARAMETER is
expanded with a property ICB_STRIPE_QUERY_PARAMETER with a value of the random string.
And the JSON structure is send to the backend using the operation "set_subscription"
Finally the backend compares the random string that is
supplied by the app and the random string that is stored in the users profile. If values
match then the backend continues trying to set the users subscription.
All this is done to ensure that a successfull Stripe Payment by a user is tried to 
a subscription update of the same user.
*/

// export const ICB_STRIPE_QUERY_PARAMETER = 'N2IyNWZlY2UyZjRhZmZkZWFmMzViOWVh'
// export const ICB_BODY_SET_SUBSCRIPTION_PARAMETER = 'ZDk4NzAwZGQ4Y2FkMzE2Y2FmZDA1Yjg3'

// Use as associative array. See https://www.geeksforgeeks.org/how-to-use-associative-array-in-typescript/
export const ENTITY_TYPE_TO_STATE_PROPERTY:  { [key: string]: string } = {
  'node--player': 'allPlayers',
  'node--practice': 'allPractices',
  'node--exercise': 'allExercises',
  'node--group': 'allGroups',
  'node--concept': 'allConcepts',
  'node--team': 'allTeams',
  'user--user': 'allUsers',
  'file--file': 'allFiles',
}

export const FILTER_USER_7_GET_ONLY_USER_7 = `?filter[uid.meta.drupal_internal__target_id]=7`;
