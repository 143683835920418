import { Fragment, useContext, useEffect, useState } from 'react';
import { Context } from '../App';
import log from "../misc/Logger";
import { EnvironmentType, TypeContext, UserType } from '../misc/Types';
import { create_checkout_session, getActionSetConfirm, getEnvironment, inAppPaymentApple } from '../misc/Functions';
import { Box, Button, Divider, Fade, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Announcement } from '@mui/icons-material';
import { WelcomePresentation } from './WelcomePresentation';
import CopyToClipboardButton from './CopyToClipboardButton';
import UserflowDropdown from './UserflowDropdown';
import JoinClub from './JoinClub';
import PracticesCalendar from './PracticesCalendar';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import AttendanceReport from './AttendanceReport';

export default function Home() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [fadeIn] = useState(true);
    const theme = useTheme();
    log.debug('Home');

    // React to an inviation from a club admin
    useEffect(() => {
        if (state.clubInvite) {
            navigate('/setsubscription');
        }
    }, [state.clubInvite])

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch({ type: 'setShowIntro', show: false }); // TO DO - I don't this this line has any effect!
    }, [])

    // Function to get the current time and return the appropriate greeting
    const getGreeting = () => {
        let firstName = state.user.data.attributes.field_first_name; // we now use 'field_first_name' instead of userName
        const hour = new Date().getHours();
        if (hour < 12) {
            return `${t('Home00')}, ${firstName}`;
        } else if (hour < 18) {
            return `${t('Home01')}, ${firstName}`;
        } else {
            return `${t('Home02')}, ${firstName}`;
        }
    };

    // Show card with message. Card has text and action
    const buttonOptional = (text: string, action: () => any) => (
        <Fragment>
            <Button
                variant="contained"
                color='secondary'
                fullWidth
                sx={{ marginTop: 3, background: '#DD6F20', color: 'white', textTransform: 'none' }}
                startIcon={<Announcement />}
                onClick={() => action()}
            >
                <Typography fontSize={14} style={{ fontWeight: 'bold', fontFamily: 'PT Sans, sans-serif', color: 'white' }}>
                    {text}
                </Typography>
            </Button>
        </Fragment>
    )

    return (
        <Fragment>
            <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto' }}>
                <WelcomePresentation />

                <Typography style={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.text.primary, fontWeight: 'bold', fontSize: '25px', paddingTop: 15 }}>
                    {getGreeting()}
                </Typography>

                <Divider sx={{ paddingTop: 2 }} />

                {/* If not prod then show environment and version */}

                {
                    getEnvironment() !== EnvironmentType.prod &&
                    <Typography>
                        {`${getEnvironment()} version ${__APP_VERSION__}${state.nativeApp ? ' (native)' : ''}`}
                    </Typography>
                }

                {/* Club user waiting for the approval of the club admin. */}
                {
                    state.user.data.attributes.field_user_type === UserType.club
                    && !state.user.data.attributes.field_club_admin_accept
                    && <Typography sx={{ paddingTop: 2, color: theme.palette.text.secondary, fontSize: { xs: 13, sm: 16 } }}>
                        {t('Home15')}
                    </Typography>
                }

                <CopyToClipboardButton />

                {
                    // If subscription not payed then show warning
                    ['pro', 'clubadmin'].includes(state.user.data.attributes.field_user_type) &&
                    new Date(state.user.data.attributes.field_payment_valid_until).getTime() < new Date().getTime() &&
                    buttonOptional(t('AlertMsg29'), () => {
                        if (state.user.data.attributes.field_app_store_original_transac) {
                            // subscription created in app store
                            if (state.nativeApp) {
                                // Show payment wall in iOS app
                                inAppPaymentApple(state, dispatch, "1")
                            } else {
                                dispatch(getActionSetConfirm(t('AlertMsg34')));
                            }
                        } else {
                            // subscription through Stripe
                            create_checkout_session(state, dispatch, { 'field_user_type': state.user.data.attributes.field_user_type, 'field_club_name': state.user.data.attributes.field_club_name })
                        }
                    })
                }

                {
                    // If free user then show text about upgrade 
                    state.user.login.current_user.uid !== 0 && state.user.data.attributes.field_user_type === 'free' &&
                    buttonOptional(t('AlertMsg06'), () => navigate('/setsubscription'))
                }

                {
                    // If club admin and new coach waiting then show message
                    state.user.data.attributes.field_user_type === 'clubadmin' &&
                    state.allUsers.find(x => x.relationships.field_my_club_admin.data?.id === state.user.data.id && !x.attributes.field_club_admin_accept) &&
                    buttonOptional(t('AlertMsg00'), () => navigate('/coaches'))
                }

                {/* Open dialog to show a single practice program in read only state */}
                {/* <DialogPracticeProgram open={dialogOpenPracticeProgramNoEdit} onClose={() => setDialogOpenPracticeProgramNoEdit(false)} /> */}

                {/* JOIN A CLUB QUICK ACCESS FROM THE FRONT PAGE */}
                {
                    state.user.data.attributes.field_user_type === 'free' &&
                    <Box sx={{ paddingTop: 1.5 }}>
                        {/* <Divider sx={{ paddingTop: 2.5 }} /> */}

                        <Button
                            variant="outlined"
                            fullWidth
                            color='secondary'
                            startIcon={<Diversity3Icon />}
                            sx={{ marginTop: 0.5, textTransform: 'none', justifyContent: 'flex-start', fontWeight: 'bold', fontSize: { xs: '12px', sm: '14px' } }}>
                            {t('Home16')}

                        </Button>
                        <Divider sx={{ paddingTop: 2 }} />
                    </Box>
                }

                {/* INFO THROUGH USERFLOW ABOUT HOW CAN ICB HELP CLUBS WITH THE CLUB STRUCTURE */}
                {
                    state.user.data.attributes.field_user_type === 'free' &&
                    <Fragment>
                        {/* <Divider sx={{ paddingTop: 2 }} /> */}
                        {/* <Typography sx={{ marginTop: 2, textTransform: 'none', fontSize: state.portrait ? '16px' : '18px', fontWeight: 'bold', color: theme.palette.primary.main }}>
                            {t('SetSubscription22')}
                        </Typography> */}
                        <JoinClub />
                        {/* <Divider sx={{ paddingTop: 3 }} /> */}
                    </Fragment>

                }

                <Fade in={fadeIn} timeout={300} style={{ transitionDelay: '100ms' }}>
                    <Box style={{ marginTop: state.user.data.attributes.field_user_type === 'free' ? '0px' : '0px' }}  >
                        {/* <WeeklyPractices /> */}
                        <PracticesCalendar />
                    </Box>
                </Fade>

                <Fade in={fadeIn} timeout={450} style={{ transitionDelay: '100ms' }}>
                    <Box style={{ marginTop: state.user.data.attributes.field_user_type === 'free' ? '0px' : '0px' }}  >
                        {/* <WeeklyPractices /> */}
                        <AttendanceReport />
                    </Box>
                </Fade>

                <Button
                    variant="outlined"
                    fullWidth
                    color='secondary'
                    style={{ marginTop: 22, textTransform: 'none', justifyContent: 'flex-start' }}
                    startIcon={<LightbulbIcon />}
                    onClick={() => navigate('/concepts')}
                >
                    <Typography fontSize={14} style={{ fontFamily: 'PT Sans, sans-serif' }}>
                        {t('Home17')}
                    </Typography>
                </Button>

                <Fade in={fadeIn} timeout={300} style={{ transitionDelay: '300ms' }}>
                    <Box>
                        <UserflowDropdown />
                    </Box>
                </Fade>
            </Box>
        </Fragment >
    )
}
